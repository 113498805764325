.read-more{
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  
}

.read-more__button {
    display: inline;
    background-color: transparent;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #007bff !important;
    cursor: pointer;
  }
  
  .read-more__button:hover {
    text-decoration: underline;
  }
  
  .read-more__text--hide {
   max-height: 0;
   font-size: 0;
   opacity: 0;
  }
  
  .read-more__text--show {
    /*max-height: 10em;*/
    opacity: 1;
    font-size: inherit;
    white-space: pre-line;
    display: block;
  }
  
  .read-more__text--remaining {
    transition: opacity 240ms ease;
  }