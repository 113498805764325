body {
  margin: 0;
  font-family: 'Avenir Book' !important;
}

a {
  color: #ffffff !important;
  text-decoration: none;
}

.landingpage-container{
  position : fixed;
  width: 100%;
  height: 100%;
  margin: auto;
  color: #ffffff;
  background-color: #191F2F;
  text-align: center;
  background-color: #ffffff; 
  background-attachment: fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  overflow: hidden;
}

.landingpage-header{
  padding : 15% 0 25% 0;
}

.landingpage-header .logo{
  width: 50%;
  height: auto;
}

.landingpage-carousel-caption{
  padding: 10% 0 0 0;
  height: 115px;
}

.landingpage-carousel-caption .header{
  font-family: 'Avenir Heavy';
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.landingpage-carousel-caption .content{
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  padding: 0 5% 0 5%;
}

.landingpage-carousel-caption > .content, .skip{
  opacity: 0.8;
}

.skip{
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  bottom: -66px;
  left: 0;
  right:0;
  margin-left: auto;
  margin-right: auto;
}

.skip:hover{
  text-decoration: none;
}

.homepage-content{
  position : relative;
  background: #000000;
  color: #ffffff;
  overflow-x: auto;
  height: calc(100vh - 6em);
  overflow-y: auto !important;
}

.homepage-header {
  padding: 0;
}

.homepage-body { 
  margin: 22px 2% 0 2%;
  padding: 15px 5% 0 5%;
  background: #191F2F;
  -webkit-border-top-left-radius:18px !important; 
  -moz-border-top-left-radius:18px !important; 
  border-top-left-radius:18px !important; 
  -webkit-border-top-right-radius:18px !important;
  -moz-border-top-right-radius:18px !important;
  border-top-right-radius:18px !important;
}

.homepage-features{
  background-color: #242A37;
  padding: 5%;
  border: 3px solid #242A37;
  margin-bottom: 5%;
  background: #242A37;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.homepage-features .content{
  font-weight: 350;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
}

.coming-soon {
  font-size: 13px;
  display: block;
  padding-bottom: 18px;
  font-weight: 500;
  line-height: 1.2;
  height: 120px;
}

.coming-soon-text {
  display:block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.homepage-features-margin-top{
  margin-top : 25px;
}

.homepage-features .content > .header{
  font-family: 'Avenir Heavy';
  font-weight: bold;
}

.homepage-features .content > .detail{
  font-weight: 300;
  opacity: 0.9;
}

.homepage-buttongroup {
  width: 100%;
  background: #D8D8D8;
  border-radius: 19px !important; 
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.224px;
  color: #030303;
}

.homepage-buttongroup .button-group-child {
  font-family: 'Avenir Heavy';
  font-weight: bold;
}

.homepage-buttongroup .button-selected, .signup-button{
  background: linear-gradient(90deg, #FF5C5C 0%, #D42961 100%);
  -webkit-border-radius: 19px !important;
  -moz-border-radius: 19px !important;
  border-radius: 19px !important;
  font-weight: 350  !important;
  color: #FFFFFF !important;
}

.bottom-spacing{
  height: 69px;
}

.experience-button{
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -70px;
  padding: 10px !important;
  background: linear-gradient(90deg, #FF5C5C 0%, #D42961 100%);
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  border-radius: 24px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.288px;
  color: #FFFFFF;
}

.detailpage-experience-button{
  background: linear-gradient(90deg, #FF5C5C 0%, #D42961 100%);
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  border-radius: 24px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.288px;
  color: #FFFFFF;
  width: 80%;
  padding: 10px !important;
  margin-bottom : 20%;
  text-align: center;
}

.detailpage-experience-button .image-cube{
  width: 27px;
  margin-right: 10px;
}

.signup-button, .experience-button{
  width: 90%;
}

.experience-button{
   margin-bottom:8%;
}

.signup-button{
  margin-bottom: 35%;
}

.carousel-indicators
{
  bottom : -70px !important; 
}

.carousel-indicators li {
  height: 9px !important;
  width: 9px !important;
  background-color: #FFFFFF !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-left : 8px !important;
  margin-right: 8px !important;
  opacity: 1 !important;
}

.carousel-indicators li.active {
  background-color: #FB3434 !important;
}

.detailpage-header{
  font-weight: 350;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.41px;
  color: #FFFFFF;
  padding : 2% 0 8% 0;
}

.detailpage-header .left{
  width : 12px;
}

.detailpage-header span{
  opacity :0.8;
}

.detailpage-container{
  text-align: center;
  position: relative;
  background-color: #191F2F;
  color: #ffffff;
  overflow-x: auto;
  height: calc(100vh - 6em);
  overflow-y: auto !important;
}

.detailpage-container .more-text{
  font-weight: bolder;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #61BEFF !important;
  cursor: pointer;
}

.detailpage-container .experience-text h5{
 display: inline;
 margin-left: 20px;
}

.detailpage-carousel-caption{
  margin-bottom : 25px;
}

.detailpage-container .unboxed-text{
  font-weight: 350;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.192px;
  color: #FFFFFF;
}

.button-image{
  padding: 0;
  border: none;
  background: none;
  position:relative;
  color : #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
}

.centered {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.horizontal-scroll .caption {
  bottom: 45px;
  width: 100%;
  margin: auto;
  opacity: 0.8;
  padding-left: 10px;
  position : relative;
  height: 0;
}

.horizontal-scroll .caption .label {
  font-weight: 350;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 2.4px;
  color: #FFFFFF;
  display: inline-table;  
}

.horizontal-scroll {
  white-space: nowrap;
  z-index: 999999;
}

.horizontal-scroll .item {
  display: inline-block;
  font-size: 20px;
  margin: 0px 8px;
  border-radius: 20px;
}

.horizontal-scroll .item:first-child {
  margin-left: 0;
}

.horizontal-scroll .item:last-child {
  margin-right: 0;
}


.footer {
  text-align: center;
  color: #ffffff;
  background: #000000;
  height: 6em;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  padding: 10px 0 0 0;
}

.footer .footer-icon {
  z-index: 1;
  font-size: 0.8rem;
  display: block;
}

.slick-slide div {
  width : 95% !important;
  position: relative;
}

.slick-list {
  margin-bottom: 10% !important;
}

.slick-dots li {
  margin :0 !important;
}

.slick-dots li.slick-active button:before {
  color: red !important;
  opacity: 1 !important;
  font-size: 8px !important;
}

 .slick-dots li button:before {
  color: #FFFFFF !important;
  opacity: 1 !important;
  font-size: 8px !important;
}

.slideContent .header {
  font-family: 'Avenir Heavy';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  margin-top : 20px;
  margin-bottom: 10px;
}

.slideContent p {
  font-weight: 350;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8 !important;
}

.carousel-item-image {
  width:80%;
  height: 188px;
}

.close {
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 9999999999;
  top: 10px;
  right: 0;
  height: 30px;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  font-family: 'Avenir Heavy';
  font-weight: bold;
  position: absolute;
  left: 15px;
  content: ' ';
  height: 17px;
  width: 2px;
  background-color: #FF5C5C;
}

.close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.carousel-control-next, .carousel-control-prev{
  display: none !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.demo-room-header{
  font-family: 'Avenir Heavy';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  height: 31px;
  display: inline-block;
  margin-bottom: 5px;
}

.detailpage-container .slick-list { 
  padding:35px 60px !important;
}

.signup-button {
  display :none !important;
}

.homepage-content .slick-slide {
  width: 136px ;
  margin-left:12px;
}

.mobileFullScreenView{
  position:fixed; 
  top:0; 
  left:0; 
  bottom:0; 
  right:0; 
  width:100% !important;
  height:100%  !important; 
  border:none; 
  margin:0; 
  padding:0; 
  overflow:hidden;
  z-index:99999;
  background: #000;
  padding-top: 30px;
}

.mobileFullScreenView .robot-image, .desktopFullScreenVideo .robot-image{
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.smartphone-container .instruction {
   display: none;
}

.smartphone-time, .smartphone-icons {
  display : none;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  .smartphone-container {
    position: absolute;
    top: 51%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* The device with borders */
  .smartphone {
    position: relative;
    width: 270px;
    height: 548px;
    margin: auto;
    background-image:url('./images/phone_template.png'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 45px 15px 15px 15px;
    background-clip: padding-box;
    background-color: #000;
    -webkit-border-radius: 43px;
    -moz-border-radius: 43px;
    border-radius: 43px;
  }

  .smartphone:before
  {
    content: '';
    display: block;
    background: #222;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 30px;
    left: 50%;
    margin-left: -77px;
    position: absolute;
    top: 15px;
    width: 155px;
    z-index: 2;
  }
  
  .carousel-item-image{
    height: 130px !important;
  }

  .footer{
    position: inherit !important;
    bottom : auto !important;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
    height: 2em;
  }

  .desktopFullScreenVideo{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #191f2f;
    width: 245px !important;
    height: 440px !important;
    margin: 45px 15px 15px 15px;
  }

  .landingpage-carousel-caption .content{
     min-height: 20px;
     padding : 0 !important;
  }

  .detailpage-header {
    font-size: 1rem;
  }

  .landingpage-container{ 
    position: inherit;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
  }

  .homepage-content, .detailpage-container{
    height: 438px;
   }

   .demo-room-header{
    font-size: 15px !important;
    height: 25px !important;
    margin-bottom: 5px;
   } 

   .homepage-buttongroup .button-group-child{
     font-size: 10px !important;
   }

   .homepage-features .content {
     font-size: 11px !important;
   }

   .detailpage-header .left{
    width: 10px;
   }

   .detailpage-header{
    font-size: 15px !important;
   }

   .detailpage-container .slideContent .header{
    font-size: 16px !important;
   }

   .detailpage-container .slideContent p {
     font-size : 11px !important;
   }

   .detailpage-container .slick-dots li{
      width: 13px !important;
      height: 13px !important;
   }

   .detailpage-container .slick-dots li.slick-active button:before {
    font-size: 5px !important;
  }
  
  .detailpage-container .slick-dots li button:before {
    font-size: 5px !important;
  }

   .detailpage-experience-button{
    font-size: 14px !important;
    padding: 5px !important;
    width: 90% !important;
   }

  .bottom-spacing{
    height:0 !important;
  }

  .homepage-content .slick-slide {
    width: 100px !important;
    margin-left:8px !important;
  }

  .horizontal-scroll .caption .label {
    font-size: 8px !important;
  }
  
  .close {
    top: 50px !important;
    right: 10px !important;
  }
  
  .carousel-indicators {
    bottom: -40px !important;
  }

  .skip{
    bottom: -24px !important;
  }

  .experience-button{
    font-size: 14px !important;
    padding: 3px !important;
    width: 100%;
    margin-bottom: 0 !important;
    bottom: -35px !important;
  }

  .landingpage-carousel-caption .header {
    font-size: 20px !important;
  }

  .landingpage-carousel-caption .content, .skip{
    font-size: 12px !important;
  }

  .carousel-indicators li {
    height: 7px !important;
    width: 7px !important;
    background-color: #FFFFFF !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-left : 6px !important;
    margin-right: 6px !important;
    opacity: 1 !important;
  }

  .detailpage-experience-button .image-cube {
    width :22px;
  }

  .smartphone-container .instruction {
    display: block;
    text-align: center;
    font-size: 16px;
  }
  
  .detailpage-container .slick-list {
    height: 205px !important;
  }
  
  .detailpage-container .slick-slide {
    width: 124px !important;
  }

  .smartphone-time{
    display: block;
    position: absolute;
    top: 18px;
    left: 20px;
    color: #ffffff;
    font-size: 8px;
  }

  .smartphone-icons{
    display: block;
    position: absolute;
    top: 8px;
    right: 15px;
    opacity: 1;
  }

  .smartphone-icons .icon {
    width: 12px;
    margin: 0px 1px;
  }

  .horizontal-scroll .item {
    width: 120px;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .smartphone-container {
    position: absolute;
    top: 51%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* The device with borders */
  .smartphone {
    position: relative;
    width: 270px;
    height: 548px;
    margin: auto;
    background-image:url('./images/phone_template.png'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 45px 15px 15px 15px;
    background-clip: padding-box;
    background-color: #000;
    -webkit-border-radius: 43px;
    -moz-border-radius: 43px;
    border-radius: 43px;
  }

  .smartphone:before
  {
    content: '';
    display: block;
    background: #222;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 30px;
    left: 50%;
    margin-left: -77px;
    position: absolute;
    top: 15px;
    width: 155px;
    z-index: 2;
  }
  
  .carousel-item-image{
    height: 130px !important;
  }

  .footer{
    position: inherit !important;
    bottom : auto !important;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
    height: 2em;
  }

  .desktopFullScreenVideo{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #191f2f;
    width: 245px !important;
    height: 440px !important;
    margin: 45px 15px 15px 15px;
  }

  .landingpage-carousel-caption .content{
     min-height: 20px;
     padding : 0 !important;
  }

  .detailpage-header {
    font-size: 1rem;
  }

  .landingpage-container{ 
    position: inherit;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
  }

  .homepage-content, .detailpage-container{
    height: 438px;
   }

   .demo-room-header{
    font-size: 15px !important;
    height: 25px !important;
    margin-bottom: 5px;
   } 

   .homepage-buttongroup .button-group-child{
     font-size: 10px !important;
   }

   .homepage-features .content {
     font-size: 11px !important;
   }

   .detailpage-header .left{
    width: 10px;
   }

   .detailpage-header{
    font-size: 15px !important;
   }

   .detailpage-container .slideContent .header{
    font-size: 16px !important;
   }

   .detailpage-container .slideContent p {
     font-size : 11px !important;
   }

   .detailpage-container .slick-dots li{
      width: 13px !important;
      height: 13px !important;
   }

   .detailpage-container .slick-dots li.slick-active button:before {
    font-size: 5px !important;
  }
  
  .detailpage-container .slick-dots li button:before {
    font-size: 5px !important;
  }

   .detailpage-experience-button{
    font-size: 14px !important;
    padding: 5px !important;
    width: 90% !important;
   }

  .bottom-spacing{
    height:0 !important;
  }

  .homepage-content .slick-slide {
    width: 100px !important;
    margin-left:8px !important;
  }

  .horizontal-scroll .caption .label {
    font-size: 8px !important;
  }
  
  .close {
    top: 50px !important;
    right: 10px !important;
  }
  
  .carousel-indicators {
    bottom: -40px !important;
  }

  .skip{
    bottom: -24px !important;
  }

  .experience-button{
    font-size: 14px !important;
    padding: 3px !important;
    width: 100%;
    margin-bottom: 0 !important;
    bottom: -35px !important;
  }

  .landingpage-carousel-caption .header {
    font-size: 20px !important;
  }

  .landingpage-carousel-caption .content, .skip{
    font-size: 12px !important;
  }

  .carousel-indicators li {
    height: 7px !important;
    width: 7px !important;
    background-color: #FFFFFF !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-left : 6px !important;
    margin-right: 6px !important;
    opacity: 1 !important;
  }

  .detailpage-experience-button .image-cube {
    width :22px;
  }

  .smartphone-container .instruction {
    display: block;
    text-align: center;
    font-size: 16px;
  }
  
  .detailpage-container .slick-list {
    height: 205px !important;
  }
  
  .detailpage-container .slick-slide {
    width: 124px !important;
  }

  .smartphone-time{
    display: block;
    position: absolute;
    top: 18px;
    left: 20px;
    color: #ffffff;
    font-size: 8px;
  }

  .smartphone-icons{
    display: block;
    position: absolute;
    top: 8px;
    right: 15px;
    opacity: 1;
  }

  .smartphone-icons .icon {
    width: 12px;
    margin: 0px 1px;
  }

  .horizontal-scroll .item {
    width: 95px;
    margin: 0 6px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .smartphone-container {
    position: absolute;
    top: 51%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* The device with borders */
  .smartphone {
    position: relative;
    width: 270px;
    height: 548px;
    margin: auto;
    background-image:url('./images/phone_template.png'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 45px 15px 15px 15px;
    background-clip: padding-box;
    background-color: #000;
    -webkit-border-radius: 43px;
    -moz-border-radius: 43px;
    border-radius: 43px;
  }

  .smartphone:before
  {
    content: '';
    display: block;
    background: #222;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 30px;
    left: 50%;
    margin-left: -77px;
    position: absolute;
    top: 15px;
    width: 155px;
    z-index: 2;
  }
  
  .carousel-item-image{
    height: 130px !important;
  }

  .footer{
    position: inherit !important;
    bottom : auto !important;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
    height: 2em;
  }

  .desktopFullScreenVideo{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #191f2f;
    width: 245px !important;
    height: 440px !important;
    margin: 45px 15px 15px 15px;
  }

  .landingpage-carousel-caption .content{
     min-height: 20px;
     padding : 0 !important;
  }

  .detailpage-header {
    font-size: 1rem;
  }

  .landingpage-container{ 
    position: inherit;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
  }

  .homepage-content, .detailpage-container{
    height: 438px;
   }

   .demo-room-header{
    font-size: 15px !important;
    height: 25px !important;
    margin-bottom: 5px;
   } 

   .homepage-buttongroup .button-group-child{
     font-size: 10px !important;
   }

   .homepage-features .content {
     font-size: 11px !important;
   }

   .detailpage-header .left{
    width: 10px;
   }

   .detailpage-header{
    font-size: 15px !important;
   }

   .detailpage-container .slideContent .header{
    font-size: 16px !important;
   }

   .detailpage-container .slideContent p {
     font-size : 11px !important;
   }

   .detailpage-container .slick-dots li{
      width: 13px !important;
      height: 13px !important;
   }

   .detailpage-container .slick-dots li.slick-active button:before {
    font-size: 5px !important;
  }
  
  .detailpage-container .slick-dots li button:before {
    font-size: 5px !important;
  }

   .detailpage-experience-button{
    font-size: 14px !important;
    padding: 5px !important;
    width: 90% !important;
   }

  .bottom-spacing{
    height:0 !important;
  }

  .homepage-content .slick-slide {
    width: 100px !important;
    margin-left:8px !important;
  }

  .horizontal-scroll .caption .label {
    font-size: 8px !important;
  }
  
  .close {
    top: 50px !important;
    right: 10px !important;
  }
  
  .carousel-indicators {
    bottom: -40px !important;
  }

  .skip{
    bottom: -24px !important;
  }

  .experience-button{
    font-size: 14px !important;
    padding: 3px !important;
    width: 100%;
    margin-bottom: 0 !important;
    bottom: -35px !important;
  }

  .landingpage-carousel-caption .header {
    font-size: 20px !important;
  }

  .landingpage-carousel-caption .content, .skip{
    font-size: 12px !important;
  }

  .carousel-indicators li {
    height: 7px !important;
    width: 7px !important;
    background-color: #FFFFFF !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-left : 6px !important;
    margin-right: 6px !important;
    opacity: 1 !important;
  }

  .detailpage-experience-button .image-cube {
    width :22px;
  }

  .smartphone-container .instruction {
    display: block;
    text-align: center;
    font-size: 16px;
  }
  
  .detailpage-container .slick-list {
    height: 205px !important;
  }
  
  .detailpage-container .slick-slide {
    width: 124px !important;
  }

  .smartphone-time{
    display: block;
    position: absolute;
    top: 18px;
    left: 20px;
    color: #ffffff;
    font-size: 8px;
  }

  .smartphone-icons{
    display: block;
    position: absolute;
    top: 8px;
    right: 15px;
    opacity: 1;
  }

  .smartphone-icons .icon {
    width: 12px;
    margin: 0px 1px;
  }

  .horizontal-scroll .item {
    width: 95px;
    margin: 0 6px;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .smartphone-container {
    position: absolute;
    top: 51%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* The device with borders */
  .smartphone {
    position: relative;
    width: 270px;
    height: 548px;
    margin: auto;
    background-image:url('./images/phone_template.png'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 45px 15px 15px 15px;
    background-clip: padding-box;
    background-color: #000;
    -webkit-border-radius: 43px;
    -moz-border-radius: 43px;
    border-radius: 43px;
  }

  .smartphone:before
  {
    content: '';
    display: block;
    background: #222;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 30px;
    left: 50%;
    margin-left: -77px;
    position: absolute;
    top: 15px;
    width: 155px;
    z-index: 2;
  }
  
  .carousel-item-image{
    height: 130px !important;
  }

  .footer{
    position: inherit !important;
    bottom : auto !important;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
    height: 2em;
  }

  .desktopFullScreenVideo{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #191f2f;
    width: 245px !important;
    height: 440px !important;
    margin: 45px 15px 15px 15px;
  }

  .landingpage-carousel-caption .content{
     min-height: 20px;
     padding : 0 !important;
  }

  .detailpage-header {
    font-size: 1rem;
  }

  .landingpage-container{ 
    position: inherit;
    -webkit-border-radius: 0 0 30px 30px;
    -moz-border-radius: 0 0 30px 30px;
    border-radius: 0 0 30px 30px;
  }

  .homepage-content, .detailpage-container{
    height: 438px;
   }

   .demo-room-header{
    font-size: 15px !important;
    height: 25px !important;
    margin-bottom: 5px;
   } 

   .homepage-buttongroup .button-group-child{
     font-size: 10px !important;
   }

   .homepage-features .content {
     font-size: 11px !important;
   }

   .detailpage-header .left{
    width: 10px;
   }

   .detailpage-header{
    font-size: 15px !important;
   }

   .detailpage-container .slideContent .header{
    font-size: 16px !important;
   }

   .detailpage-container .slideContent p {
     font-size : 11px !important;
   }

   .detailpage-container .slick-dots li{
      width: 13px !important;
      height: 13px !important;
   }

   .detailpage-container .slick-dots li.slick-active button:before {
    font-size: 5px !important;
  }
  
  .detailpage-container .slick-dots li button:before {
    font-size: 5px !important;
  }

   .detailpage-experience-button{
    font-size: 14px !important;
    padding: 5px !important;
    width: 90% !important;
   }

  .bottom-spacing{
    height:0 !important;
  }

  .homepage-content .slick-slide {
    width: 100px !important;
    margin-left:8px !important;
  }

  .horizontal-scroll .caption .label {
    font-size: 8px !important;
  }
  
  .close {
    top: 50px !important;
    right: 10px !important;
  }
  
  .carousel-indicators {
    bottom: -40px !important;
  }

  .skip{
    bottom: -24px !important;
  }

  .experience-button{
    font-size: 14px !important;
    padding: 3px !important;
    width: 100%;
    margin-bottom: 0 !important;
    bottom: -35px !important;
  }

  .landingpage-carousel-caption .header {
    font-size: 20px !important;
  }

  .landingpage-carousel-caption .content, .skip{
    font-size: 12px !important;
  }

  .carousel-indicators li {
    height: 7px !important;
    width: 7px !important;
    background-color: #FFFFFF !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-left : 6px !important;
    margin-right: 6px !important;
    opacity: 1 !important;
  }

  .detailpage-experience-button .image-cube {
    width :22px;
  }

  .smartphone-container .instruction {
    display: block;
    text-align: center;
    font-size: 16px;
  }
  
  .detailpage-container .slick-list {
    height: 205px !important;
  }
  
  .detailpage-container .slick-slide {
    width: 124px !important;
  }

  .smartphone-time{
    display: block;
    position: absolute;
    top: 18px;
    left: 20px;
    color: #ffffff;
    font-size: 8px;
  }

  .smartphone-icons{
    display: block;
    position: absolute;
    top: 8px;
    right: 15px;
    opacity: 1;
  }

  .smartphone-icons .icon {
    width: 12px;
    margin: 0px 1px;
  }

  .horizontal-scroll .item {
    width: 95px;
    margin: 0 6px;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .landingpage-container{
    overflow-y: auto;
  }
}

.detailpage-container .slick-center  .slide-item-image {
  -webkit-animation: scale 400ms ease-in-out forwards;
  -moz-animation: scale 400ms ease-in-out forwards;
  -o-animation: scale 400ms ease-in-out forwards;
  -ms-animation: scale 400ms ease-in-out forwards;
  transform: scale(1.25);  
}

@-webkit-keyframes scale {
  to {
    -webkit-transform: scale(1.25);  
  }
}

@-moz-keyframes scale {
  to {
    -moz-transform: scale(1.25);  
  }
}

@-o-keyframes scale {
  to {
    -o-transform: scale(1.25);  
  }
}

@-ms-keyframes scale {
  to {
    -ms-transform: scale(1.25);  
  }
}


@keyframes scale {
  to {
    transform: scale(1.25);  
  }
}